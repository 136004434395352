::selection {
  background-color: #f5a637;
  color: #FFF;
}

@media (min-width: 768px) {
  .my-md-5 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .pt-md-9 {
    padding-top: 6rem !important;
  }

  .pt-md-15 {
    padding-top: 15rem !important;
  }

  .pb-md-13 {
    padding-bottom: 12rem !important;
  }

  .py-md-9 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.svg-inline--fa.fa-w-10 {
  width: .625em;
}

.svg-inline--fa.fa-w-14 {
  width: .875em;
}

.svg-inline--fa.fa-w-12 {
  width: .75em;
}

header {
  background-image: none;
  position: relative;
  z-index: 0;
}

.btn-outline-primary {
  background-color: transparent;
  color: #000;
  border-color: #f5a637;
}

.btn-outline-primary:hover {
  background-color: transparent;
  color: #f5a637;
  border-color: #f5a637;
}

.text-primary,
.text-primary-hover:hover {
  color: #f5a637 !important;
}

.text-xs {
  font-family: Lato, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  letter-spacing: .05em;
  text-transform: uppercase;
}

.display-1,
.display-2 {
  font-weight: 700;
  line-height: 1;
}

.navbar-brand {
  font-size: 2rem;
  margin-right: 1rem;
  padding-bottom: -.35rem;
  padding-top: -.35rem;
  text-decoration: none;
  white-space: nowrap;

  font-family: Playfair Display, serif;
  font-style: italic;
  transition: color .1s;
}

.navbar .navbar-nav .nav-link {
  font-family: Lato, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  letter-spacing: .05em;
  text-transform: uppercase;
  transition: color .1s;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #f5a637;
}

.btn {
  border-radius: 0;
  display: inline-block;
  line-height: 1.25rem;
  padding: 1rem 2rem;
  text-align: center;
  transition: color .1s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;

  font-family: Lato, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  letter-spacing: .05em;
  text-transform: uppercase;
}

.navbar::before {
  background-color: #fff;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  transition: all .3s;
  z-index: -1;
}

.navbar-light::before {
  transform: translateY(0);
}

.ms-5 {
  margin-left: 2rem !important;
}

.bg-black-50 {
  background-color: rgba(0, 0, 0, .5) !important;
}

.hr-sm:not([size]) {
  height: 3px;
}

.hr-sm {
  width: 2rem;
}

.bg-primary {
  background-color: #f5a637 !important;
}

hr {
  border: 0;
  color: #e9ecef;
  margin: 1rem 0;
  opacity: 1;
}

.md-7 {
  padding-top: 3rem;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #f5a637;
}

.bg-light {
  background-color: #e9ecef !important;
}

.font-serif {
  font-family: Playfair Display, serif !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.contactForm ::before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Ccircle cx='10' cy='10' r='5' fill='%23e9ecef'/%3E%3C/svg%3E");
  background-size: 10px 10px;
  bottom: -20%;
  content: "";
  height: 50%;
  position: absolute;
  right: 75%;
  width: 35%;
  z-index: -1;
}